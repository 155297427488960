import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';

import { Container } from '@/components/Layout/Container';
import Text from '@/components/Text/Text';

const FooterWrapper = styled.footer`
  position: relative;
  z-index: 100;
  min-height: 20rem;
  padding-top: 5rem;
  margin-top: auto;
  background-color: ${(props) => props.theme.colors.white};
  // background-color: #243b55;

  .container {
    padding-bottom: 6rem;
  }

  a.footerLink {
    color: ${(props) => props.theme.colors.primaryText};
    transition: all 0.3s ease;
    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

type FooterLink = {
  id: string;
  path: string;
  label: string;
  hash: string | null;
};

type FooterContent = {
  landingContentJson: {
    footer: FooterLink[];
  };
};

const query = graphql`
  {
    landingContentJson {
      footer {
        hash
        id
        label
        path
      }
    }
  }
`;

const Footer: React.FC = () => {
  const {
    landingContentJson: { footer },
  } = useStaticQuery<FooterContent>(query);

  const [sectionLinks, otherLinks] = footer.reduce<Array<FooterLink[]>>(
    (acc, link) => {
      const [slinks, olinks] = acc;

      if (link.path === '/') {
        return [[...slinks, link], olinks] as Array<FooterLink[]>;
      }
      return [slinks, [...olinks, link]] as Array<FooterLink[]>;
    },
    [[], []],
  );

  const handleScroll: React.MouseEventHandler = async (event) => {
    event.preventDefault();
    const { pathname, hash } = event.currentTarget as HTMLAnchorElement;

    if (window.location.pathname !== '/') {
      await navigate(`${pathname}${hash}`);
    } else {
      window.history.pushState(null, null, `${pathname}${hash}`);
    }

    const targetSection = document.querySelector(hash);

    if (targetSection !== null) {
      const { top: relativeTopPosition } =
        targetSection.getBoundingClientRect();
      const offsetPosition = relativeTopPosition + window.scrollY;

      window.scrollTo({
        top: offsetPosition - 75,
        behavior: 'smooth',
      });
    }
  };

  return (
    <FooterWrapper>
      <Container className="container">
        <Box
          height="1px"
          backgroundColor="#f1f4f6"
          width={['90%', '90%', '100%']}
          marginX="auto"
          marginBottom="6rem"
        />
        <Flex
          color="primaryText"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Flex
            paddingX="1.5rem"
            flexBasis={['100%', '100%', '33%']}
            flexDirection="column"
            minHeight={['15rem', '15rem', '0']}
          >
            <Link to="/">
              <Text as="h2" textStyle="secondaryHeading" marginBottom="1rem">
                Palestra
              </Text>
            </Link>
            <Box>
              <Text textStyle="displayMedium">
                Palestra App, Copyright &copy; 2025
              </Text>
            </Box>
          </Flex>
          <Flex
            paddingX="1.5rem"
            flexBasis={['100%', '100%', '20%']}
            flexDirection="column"
          >
            <Box paddingBottom="1.5rem">
              <Text textStyle="displayMedium" fontWeight={700}>
                Liens
              </Text>
            </Box>
            <ul>
              {sectionLinks.map(({ id, path, hash, label }) => (
                <Box as="li" key={id} py="0.5rem">
                  <Link
                    to={`${path}#${hash}`}
                    onClick={handleScroll}
                    className="footerLink"
                  >
                    <Text textStyle="displayMedium">{label}</Text>
                  </Link>
                </Box>
              ))}
              {otherLinks.map(({ id, path, label }) => (
                <Box as="li" key={id} py="0.5rem">
                  <Link to={path} className="footerLink">
                    <Text textStyle="displayMedium">{label}</Text>
                  </Link>
                </Box>
              ))}
            </ul>
          </Flex>
        </Flex>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
